import React from 'react';
import { Banner } from '@components/Banner';
import { Frown } from 'react-feather';
import { StandardPageDimension } from '@components/PageFormat';
import logger from '@utils/logger';

const Custom404 = (): JSX.Element => {
  logger.debug('404 location info', window.location);
  return (
    <>
      <Banner />
      <StandardPageDimension>
        <div className="flex flex-col items-center ">
          <div className="flex items-center justify-center w-16 h-16 p-2 rounded-full bg-brand-accent">
            <Frown className="h-14 w-14 text-brand" />
          </div>
          <h1 className="pt-4">404</h1>
          <h2 className="pt-4">{"Sorry, we can't find that page!"}</h2>
        </div>
      </StandardPageDimension>
    </>
  );
};

export default Custom404;
